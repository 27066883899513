<template>
    <div class="f-body">
        <div class="item">
            <h5>函数: {{ showData.name }}</h5>
            <div class="info">
                <el-alert
                    :title="showData.description"
                    :closable="false"
                    type="info">
                </el-alert>
            </div>
        </div>
        <div class="item">
            <h5>语法:</h5>
            <div class="info">
                <el-alert
                    :title="showData.method||'无'"
                    :closable="false"
                    type="info">
                </el-alert>
            </div>
        </div>
        <div class="item">
            <h5>参数:</h5>
            <div class="args">
                <div v-if="showData&&showData.arguments.length" class="arg"  :key="item.arg+index" v-for="(item,index) in showData.arguments">
                    <div class="arg-title">{{ item.arg }}:</div>
                    <div class="arg-info" v-html="item.description"></div>
                </div>
                <div v-if="!showData.arguments||!showData.arguments.length"  class="arg">
                    无
                </div>
            </div>
        </div>
        <div class="item">
            <h5>示例:</h5>
            <div class="examples">
                <div v-if="showData.examples&&showData.examples.length" class="example" :key="item.expression+index" v-for="(item,index) in showData.examples">
                    <div class="arg-title">{{ item.expression }}</div>
                    <div class="arg-info">{{ item.returns }}</div>
                </div>
                <div v-if="!showData.examples||!showData.examples.length" class="example">
                    无
                </div>

            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        detail: {
            type: Object,
            require: true,
        }
    },
    data() {
        return {}
    },
    computed: {
        showData: function () {
            return this.detail;
        }
    },
    methods: {}
}
</script>
<style scoped lang="less">
.f-body {
    padding: 0 10px;

    .item {
        margin-bottom: 20px;
    }

    h5 {
        color: #666;
        padding: 10px 0;
    }

    .info {
        //margin-top: 10px;
    }
}

.args {
    padding: 8px 16px;
    background-color: #f4f4f5;
    color: #909399;
    display: flex;
    flex-direction: column;
    //align-items: ;
    .arg {
        display: flex;
        padding: 6px 0;
        //margin-bottom: 15px;
        .arg-title {
            min-width: 85px;
            flex-shrink: 0;
            padding-right: 10px;
            color: #ce4747;
        }
        .arg-info{
            flex: 1;
        }
    }
}
.examples {
    padding: 8px 16px;
    background-color: #f4f4f5;
    color: #909399;
    display: flex;
    flex-direction: column;
    //align-items: ;
    .example {
        display: flex;
        padding: 6px 0;
        //margin-bottom: 15px;
        .arg-title {
            min-width: 120px;
            flex-shrink: 0;
            padding-right: 30px;
            color: #ce4747;
        }
        .arg-info{
            flex: 1;
        }
    }
}

</style>