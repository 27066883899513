<template>
    <div class="f-area">
        <div class="tree-area">
            <div class="top-search">
                <el-autocomplete popper-class="my-function-autocomplete" :debounce="100" :trigger-on-focus="false" @select="handleSelect"
                                 :fetch-suggestions="querySearch" size="mini"  v-model="search" placeholder="查询"
                                 suffix-icon="el-icon-search">
                    <template slot-scope="{ item }">
                        <div class="name">{{ item.value }}</div>
                        <span class="addr">{{ item.description }}</span>
                    </template>
                </el-autocomplete>
            </div>
            <div class="tree">
                <el-tree ref="tree" :data="lists" node-key="id"
                         :props="{ children: 'children',
          label: 'title'}" @node-click="handleNodeClick">
                    <div slot-scope="scope">
                        <span :id="'node_'+scope.data.id">
                            {{ scope.data.title }}
                        </span>
                    </div>
                </el-tree>
            </div>
        </div>
        <div class="f-content">
            <function-content :detail="detail"></function-content>
        </div>
    </div>
</template>
<script>

import FunctionContent from "./function-content.vue";
import functionLists from "@/assets/help/functionLists";

let obj = {
    tree: '',
}

export default {
    components: {FunctionContent},
    data() {
        return {
            detail: {
                id: '',
            },
            search: '',
            titles: [],
            lists: [],
        }
    },
    created() {
        this.initList();
    },
    mounted() {
        // window.obj = obj;
        obj.tree = this.$refs.tree;
    },
    methods: {
        handleNodeClick: function (node) {
            //如果不是单击了对应的函数
            // console.log(node);
            if (obj.dom) {
                obj.dom.style.color = '';
            }
            if (!node.info) return;
            if (this.detail.id !== node.info.id && node.info.id.indexOf('-') > -1) {
                this.detail = node.info;
            }
        },
        initList() {
            let list = [];
            functionLists.map((v, index) => {
                let item = {title: v.title, children: [], id: index.toString()};
                v.children.map((vv, index2) => {
                    if (vv[1]) {
                        let id = item.id + '-' + index2.toString();
                        vv[1].id = id;
                        item.children.push({title: vv[1].name, info: vv[1], id});
                        this.titles.push({
                            title: vv[1].name || '',
                            pid: item.id,
                            id,
                            description: vv[1].description || ''
                        });
                    }
                });
                list.push(item);
            });
            this.lists = list;
        },
        handleSelect(data) {
            if (data && data.id.indexOf('-') > -1) {
                let node = obj.tree.getNode(data.id);
                obj.tree.store.nodesMap[data.pid].expanded = true;
                setTimeout(() => {
                    this.handleNodeClick(node.data);
                    let dom = document.getElementById('node_' + data.id);
                    if (obj.dom) {
                        obj.dom.style.color = '';
                    }
                    if (dom) {
                        dom.style.color = 'red';
                        obj.dom = dom;
                        dom.scrollIntoView({
                            behavior: "smooth", // 平滑滚动
                            block: "start",     // 对齐到视图顶部
                        });
                    }
                }, 150);
            }
        },
        querySearch(query, callback) {
            let data = [];
            query = query.toString().trim().toLocaleLowerCase();
            if (query) {
                this.titles.map(v => {
                    if (v.title.toLocaleLowerCase().indexOf(query) > -1 || v.description.toLocaleLowerCase().indexOf(query) > -1) {
                        data.push({value: v.title, id: v.id, pid: v.pid,description:v.description});
                    }
                });
            } else {
                this.titles.map(v => {
                    data.push({value: v.title, id: v.id});
                });
            }
            callback(data);
        }
    }
}
</script>
<style scoped lang="less">
.f-area {
    height: 100%;
    display: flex;
    padding: 20px;
    justify-content: space-around;

    .tree-area {
        flex-shrink: 0;
        max-height: 100%;
        width: 200px;
        padding: 10px;
        background: #fff;
        overflow: auto;
        display: flex;
        flex-direction: column;

        .top-search {
            flex-shrink: 0;
            height: 45px;
        }

        .tree {
            flex: 1;
            overflow: auto;
        }
    }

    .f-content {
        flex-shrink: 0;
        //width: 500px;
        width: calc(100vw - 251px);
        overflow: auto;
        height: 100%;
        background: #fff;
    }
}

</style>